<div class="row" id="headerBar">
  <h1 id="pageTopic"> &nbsp;&nbsp;&nbsp;Configurations</h1>
</div>

<br>
<br>
<br>
<br>
<br>

<nb-card>
  <!--nb-card-header>
       <div class="content-header">
         <div class="container-fluid">
           <div class="row mb-2">
             <div class="col-sm-6">
               <h1 class="m-0 text-dark">Enter User Details</h1>
             </div>
           </div>
         </div>
       </div>

     </nb-card-header-->

  <nb-card-body>
    <div class="container" id="user-form">
      <div [hidden]='!isSuperAdmin'  class="row">
        <div class="form-group detail-input-group">
        <label class="col-md-6 col-form-label" for="name">Tenant name: </label>
        <nb-select  class="tenant-select" [(selected)]="tenantID" [(ngModel)]="tenantID" (selectedChange)="onChangeSelect($event)" placeholder="Select Tenant">
          <nb-option *ngFor="let item of tenantList" [value]="item.value">{{item.name}}</nb-option>
        </nb-select>
      </div>
    </div>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-6 col-md-offset-2 col-form-label" for="name">DOB Verification : </label>
          <nb-toggle [(ngModel)]="doBVerification"></nb-toggle>
        </div>
      </div>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-6 col-md-offset-2 col-form-label" for="name">Skip Liveness : </label>
          <nb-toggle [(ngModel)]="skipLiveness"></nb-toggle>
        </div>
      </div>

      <div class="row">
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Forgery Detection : </label>
          <nb-toggle [(ngModel)]="forgeryDetection" [disabled] ="true"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Skip Input Name : </label>
          <nb-toggle [(ngModel)]="skipInputName"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Show Scanned Images : </label>
          <nb-toggle [(ngModel)]="showScannedImages"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Hide Footer : </label>
          <nb-toggle [(ngModel)]="hideFooter"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6 col-md-offset-2 col-form-label" for="name">Enable Country Selection : </label>
          <nb-toggle [(ngModel)]="enableCountryList"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6 col-md-offset-2 col-form-label" for="name">Enable Live Video : </label>
          <nb-toggle [(ngModel)]="enableLiveVideo"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Show Composed Video (Live) : </label>
          <nb-toggle [(ngModel)]="showComposedVideo"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6 col-md-offset-2 col-form-label" for="name">Default Web Template : </label>
          <nb-toggle [(ngModel)]="defaultWebTemplate"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6 col-md-offset-2 col-form-label" for="name">Is Active Callback Authorization : </label>
          <nb-toggle [(ngModel)]="activeCallbackUrlAuthorization"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Show Other Information : </label>
          <nb-toggle [(ngModel)]="showOtherInfo" [disabled] ="true"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Show Tenant Name With Powered by : </label>
          <nb-toggle [(ngModel)]="showFooterTenantName"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Show Enduser Verification History : </label>
          <nb-toggle [(ngModel)]="showEndUserVerificationHistory"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Geo-Location Required : </label>
          <nb-toggle [(ngModel)]="geoLocationRequired"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">ID Card Parsed Data : </label>
          <nb-toggle [(ngModel)]="enableIDParsedData"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Enable Static Tokens : </label>
          <nb-toggle [(ngModel)]="enableStaticTokens"></nb-toggle>
        </div>
      </div>

      <div class="row" [hidden]='!isSuperAdmin'>
        <div class="form-group detail-input-group">
          <label class="col-md-6  col-md-offset-2 col-form-label" for="name">Enable Live Video Mode : </label>
          <nb-toggle [(ngModel)]="enableLiveVideoMode"></nb-toggle>
        </div>
      </div>

      <br>
      <br>

      <div class="form-group row">
        <div class="col-md-2 col-md-offset-7 input-group">
          <ngx-spinner bdColor="rgb(51, 153, 255,0.1)" size="medium" color="#0073e6" type="square-jelly-box"
            [fullScreen]="true" size="medium">
            <p class="loading">Updating ....</p>
          </ngx-spinner>
          <button type="button" class="btn btn-primary btn-lg" (click)="onUpdate()">Update</button>

        </div>
      </div>

      <br>

      <div class="raw" [hidden]='isSuperAdmin || !enableCountryList'>
        <ng2-smart-table class="tbl-col dataTable" id='testTable' [settings]="tableSettings"
            [source]="source">
        </ng2-smart-table>
    </div>


    </div>
  </nb-card-body>
</nb-card>
<!-- Content Wrapper. Contains page content -->
