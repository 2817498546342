import { Location } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { LocalDataSource } from 'ng2-smart-table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/app/configuration/configuration.service';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../profile/profile.service';
import { AlertService } from '../../shared/_alert/alert.service';
import { VerificationsService } from '../verifications.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SocialAccount } from 'src/app/agent/video-verification/video-verification.component';

interface MRZCheckDigit {
  docNumber: boolean ;
  dateOfBirth: boolean;
  dateOfExpiry: boolean;
  personalNumber: boolean;
  overall: boolean;
}

@Component({
  selector: 'app-verifications-detail',
  templateUrl: './verifications-detail.component.html',
  styleUrls: ['./verifications-detail.component.scss'],
})
export class VerificationsDetailComponent implements OnInit {

  @Input() name2: string;
  @ViewChild("videoElement") video: ElementRef;

  assetURL = "";

  // Modify this with the actual mime type and codec
  mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';

  googleMAPKey = environment.googleMapAPIKey;

  constructor(
    private verificationsService: VerificationsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private location: Location,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    public alertService: AlertService,
    private sanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private https: HttpClient
  ) { }

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };

  qrCode: string;
  name: String;
  identifier: String;
  documentType: String;
  documentScore: any;
  country: String;
  riskFactor: String;
  faceMatches: Number;
  livenessDTO: any;
  nameScore: any;
  longitude: String;
  latitude: String;
  userAgent: String;
  countryCode: String;
  countryCodeStr: String;
  forgeryStatus: any;
  forgeryResult: String;
  failureReason: String;
  statusInfo: String;
  timeStamp: String;
  imageHistory = [];
  alternateTextScore: any;
  customProperties: any;
  forgeryDetection: any;
  verifyStatus: string;
  email: string;
  token: string;
  liveVideo: string;
  processName: string = '';

  barcodeOutputString : any;
  barcodeOutput: JSON;
  ocrParseOutput: JSON;
  mrzOcrComparisonResult: JSON;
  mrzCheckDigitResult: any;
  mrzChechDigit : MRZCheckDigit ;
  inputdob: any;

  docNum: any;
  dob: any;
  doe: any;
  personalNum: any;
  overall: any;

  mapURL = '';
  ageScore = '';
  userStatus: String;
  enableEditView = false;
  showScannedImages = false;
  showOtherInfo = false;
  public show: boolean = false;
  showComposedVideo = false;

  inProgress = 'Pending';
  expired = 'Expired';
  success = 'Success';
  error = 'Error';
  failed = 'Failed';
  started = 'Started';
  Live = 'Live';
  Scan = 'Scan';
  userType = '';
  tenantId = '';
  pageOffset = 0;
  queueId = 0;

  agentDetails: boolean = false;
  agentComment: string;
  agentSocialResult: string;
  agentVerifyDob: boolean;
  agentVerifyDoc: boolean;
  agentVerifyCountryState: boolean;
  agentVerifyName: boolean;
  verifiedByAgentName: any;
  verifiedTime:  any;

  displayValue = '';
  requestType = '';
  fetchImageMessageShow: boolean = false;
  socialDetails;
  registeredAccounts: Array<SocialAccount> = [];
  number: string;
  riskScore: number;
  timezone: any;
  timeZoneOffset = new Date().getTimezoneOffset();
  statusList: any = {
    Pending: 'in_progress',
    Success: 'verified',
    Failed: 'failed',
  };

  otherDetails: Boolean;
  address: any;
  cuil: any;
  aadhaar: any;

  ic: any;
  nationality: any;
  dateOfIssue: any;

  rntrc: any;

  countryCode_argentina : any = 'ARG';
  countryCode_paraguay : any = 'PRY';
  countryCode_ukrain : any = 'UKR';
  countryCode_france : any = 'FRA';
  countryCode_india : any = 'IND';

  checkContentsInAddress: String = 'REPUBLIC';


  videoHistory = [];
  showVideolist: boolean = false;
  prevTransactionHistory = [];
  showEndUserVerificationHistory;
  showEnduserHistorySection: Boolean;
  enableIDParsedData: Boolean;

  activeHeartAPI: Boolean;
  ocrOutputParsedDetails: any;
  ocrOutputBackParsedDetails: any;
  enableLiveVideoMode: Boolean;

  source: LocalDataSource = new LocalDataSource();
  tableSettings = {
    mode: 'external',
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right',
    },
    edit: {
      editButtonContent: '<i class="fas fa-user-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="fas fa-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      entry: {
        title: 'Entry',
        type: 'String',
        width: '40%',
        filter: false,
      },
      match: {
        title: 'Match',
        type: 'String',
        width: '20%',
        filter: false,
      },
      confidence: {
        title: 'Confidence',
        type: 'Number',
        width: '40%',
        filter: false,
      },
    },
  };

  changeColor(livenessDTO) {
    if (livenessDTO) {
      return 'blue';
    }
    return 'red';
  }

  imageMapper(data) {
    let list = [];
    data.forEach((element) => {
      // console.info(JSON.stringify(element));
      if (element.processName != null && element.processName !== 'undefined') {
        this.processName = ((element.processName != undefined && element.processName === 'face') ? 'Face' :
          (element.processName === 'input') ? "Id Card" :
           (element.processName ==='input_cropped') ? "Id Card-Cropped" :
            (element.processName === 'input_back') ? "Id Card Back" :
             (element.processName === 'input_back_cropped') ? "Id Card Back-Cropped" :
              (element.processName === 'video') ? "Video" : '');
      }

      var title = moment(element.uploadedTime).format('MMMM Do YYYY, h:mm:ss a') + '\xa0\xa0\xa0\xa0\xa0\xa0' + this.processName.toLocaleUpperCase();

      let obj = {
        image: element.url,
        thumbImage: element.url,
        title: title
      };
      list.push(obj);
    });
    return list;
  }

  videoMapper(data) {
    let list = [];
    data.forEach((element) => {
      if(element.compositionStatus === 'AVAILABLE'){
        this.showVideolist= true;
      }

      let obj = {
        url: element.cloudFrontURL,
        status: element.compositionStatus
      };
      list.push(obj);
    });
    return list;
  }

  changeStatusColor(status) {
    if (status === 'verified') {
      this.displayValue = this.success;
      return this.styles.success;
    } else if (status === 'failed') {
      this.displayValue = this.failed;
      return this.styles.failed;
    } else if (status === 'started') {
      this.displayValue = this.started;
      return this.styles.started;
    } else if (status === 'timed_out') {
      this.displayValue = this.expired;
      return this.styles.expired;
    } else if (status === 'error') {
      this.displayValue = this.error;
      return this.styles.error;
    } else {
      this.displayValue = this.inProgress;
      return this.styles.pending;
    }
  }

  changeRequestTypeColor(value) {
    if (value === 'Live') {
      this.requestType = this.Live;
      return this.styles.live;
    } else {
      this.requestType = this.Scan;
      return this.styles.scan;
    }
  }

  styles = {
    pending: {
      'background-color': '#ffc107',
      color: 'gray',
    },
    success: {
      'background-color': '#28a745',
      color: 'white',
    },
    failed: {
      'background-color': '#dc3545',
      color: 'white',
    },
    started: {
      'background-color': '#2a70bf',
      color: 'white',
    },
    error: {
      'background-color': '#b81220',
      color: 'white',
    },
    expired: {
      'background-color': '#807d7d',
      color: 'white',
    },
    live: {
      'background-color': '#1ABD32',
      color: 'white',
    },
    scan: {
      'background-color': '#1ABD32',
      color: 'white',
    },
  };

  onBack() {
    if (this.userType != 'agent') {
      this.router.navigate(['verifications'], {
        queryParams: { tenantID: this.tenantId, pageOffset: this.pageOffset },
      });
    } else {
      this.router.navigate(['verify']);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.identifier = params['id'];
    });
    this.route.queryParams.subscribe((params) => {
      this.tenantId = params['tenantID'];
      this.pageOffset = params['pageOffset'];
      this.queueId = params['queueId'];
      this.verifyStatus = params['status'];
    });
    this.tableSettings = Object.assign({}, this.tableSettings);
    const user = this.profileService.getUserDetail();
    this.email = this.profileService.getUserDetail()["email"];
    this.userType = user.userType;

    this.getTenantConfigurationDetail();
    this.getDetail(this.identifier);
  }

  getDetail(identifier) {
    if (this.userType == 'super_admin') {
      this.verificationsService
        .getVerificationsDetailByTenantId(this.tenantId, identifier)
        .subscribe(
          (verifications) => {
            this.spinner.hide();
            this.initDetail(verifications);
          },
          (error) => {
            this.spinner.hide();
          }
        );
    } else {
      this.verificationsService.getVerificationsById(identifier).subscribe(
        (verifications) => {
          this.initDetail(verifications);
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
    }
  }

  getTenantConfigurationDetail() {
    if (this.userType == 'super_admin') {
      const api = this.configurationService.getConfigurationBySuperAdmin(
        this.tenantId
      );
      api.subscribe(
        (tenantConfiguration) => {
          // console.log(tenantConfiguration);
          this.forgeryDetection = tenantConfiguration.forgeryDetection;
          this.showEndUserVerificationHistory = tenantConfiguration.showEndUserVerificationHistory;
          this.enableLiveVideoMode = tenantConfiguration.enableLiveVideoMode;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      if (this.userType == 'tenant_admin') {
        const api = this.configurationService.getConfiguration();
        api.subscribe(
          (tenantConfiguration) => {
            // console.log(tenantConfiguration);
            this.forgeryDetection = tenantConfiguration.forgeryDetection;
            this.showEndUserVerificationHistory = tenantConfiguration.showEndUserVerificationHistory;
            this.activeHeartAPI = this.userType === 'super_admin' || this.userType === 'tenant_admin' && this.enableIDParsedData;
            this.enableLiveVideoMode = tenantConfiguration.enableLiveVideoMode;
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  initDetail(verifications) {
    // console.log(verifications);
    this.identifier = verifications.identifier;
    this.name = verifications.name;
    this.documentType = verifications.documentType || 'unknown';
    this.documentScore = verifications.documentScore === 'N/A' ? 'N/A' : parseFloat(verifications.documentScore || 0).toFixed(2) + ' %';
    this.country = verifications.country;
    this.countryCode = verifications.countryCode != null ? verifications.countryCode : '';
    this.countryCodeStr = verifications.countryCode != null ? '(' + verifications.countryCode + ')' : '';
    this.liveVideo = (verifications.liveVideo === true ? "Live" : "Scan");

    this.riskFactor = verifications.riskFactor === 'N/A' ? 'N/A' : parseFloat(verifications.riskFactor || 0).toFixed(2) + ' %';
    this.failureReason = verifications.failureReason || 'N/A';

    this.nameScore = verifications.nameScore === 'N/A'  ? 'N/A' : parseFloat(verifications.nameScore || 0).toFixed(2) + ' %';
  //  this.faceMatches = verifications.faceMatches != null && verifications.faceMatches.length > 0 ? verifications.faceMatches[0] : 0;
    this.faceMatches = verifications.faceMatches != null && verifications.faceMatches.length > 0 ? (Math.floor(verifications.faceMatches[0] * 100)/100) : 0;
    this.livenessDTO = verifications.livenessDTO == null || verifications.livenessDTO.live === 'SKIPPED' ? 'N/A' : this.getLiveness(verifications.livenessDTO);
    this.longitude = verifications.longitude || null;
    this.latitude = verifications.latitude || null;
    this.userAgent = verifications.userAgent;
    this.qrCode = verifications.qrCode || '';
    this.userStatus = verifications.status;
    this.statusInfo = verifications.statusInfo || '';
    this.token = verifications.token || '';
    this.ageScore = verifications.dobScore == null || verifications.dobScore === 'N/A' ? 'N/A' : parseFloat(verifications.dobScore).toFixed(2) + ' %';
    this.inputdob = (verifications.dateOfBirth == null || verifications.dateOfBirth === '' || verifications.dateOfBirth === 'N/A') ? '-' : verifications.dateOfBirth ;
    this.forgeryStatus = this.getForgeryStatus(verifications.forgeryStatus);
    this.forgeryResult = verifications.forgeryResult || '';

	  this.timeStamp = moment(verifications.requestedTime).format('MMMM Do YYYY, h:mm:ss a');
    this.timezone =  this.getTimeZone();

    this.showScannedImages = verifications.showScannedImages;
    this.showOtherInfo = verifications.showOtherInfo;
    this.alternateTextScore = verifications.alternateTextScore === 'N/A' ? 'N/A' : parseFloat(verifications.alternateTextScore || 0).toFixed(2) + ' %' ;
    if (verifications.validations != null) {
      this.source.load(verifications.validations);
    }
    this.changeColor(this.livenessDTO);
    this.changeStatusColor(this.userStatus);
    if (this.showScannedImages) {
      this.imageHistory = this.imageMapper(verifications.imageHistory);
    }

    this.showComposedVideo = verifications.showComposedVideo;
    if (this.showComposedVideo && verifications.liveVideo === true) {
      this.videoHistory = this.videoMapper(verifications.liveVideoCompositionDTO);
    }

    this.customProperties = verifications.customProperties == null ? 'N/A' : this.getCustomProperties(verifications.customProperties);

    this.otherDetails = verifications.otherDetails != null && (verifications.status == 'failed' || verifications.status == 'verified')
     ? true : false ;

    if(verifications.otherDetails != null && verifications.otherDetails.address === null && verifications.otherDetails.cuil === null ){
      this.otherDetails = false;
    } else if(verifications.otherDetails != null && verifications.otherDetails.address.includes(this.checkContentsInAddress) && (verifications.otherDetails.cuil === "" || verifications.otherDetails.cuil === null)) {
      this.otherDetails = false;
    }

    if((this.documentType === 'National ID' || this.documentType === 'Aadhaar') &&
      (verifications.countryCode != null && verifications.countryCode === 'PRY'
      || verifications.countryCode === 'UKR' || verifications.countryCode === 'ARG'
      || verifications.countryCode === 'FRA' || verifications.countryCode === 'IND')) {
      this.otherDetails = true;
    }

    this.address = verifications.otherDetails != null && verifications.otherDetails.address != null ? verifications.otherDetails.address : "" ;
    this.cuil = verifications.otherDetails != null && verifications.otherDetails.cuil != null  ? verifications.otherDetails.cuil : "" ;
    this.aadhaar = verifications.otherDetails != null && verifications.otherDetails.aadhaar != null  ? verifications.otherDetails.aadhaar : "" ;

    this.ic = verifications.otherDetails != null && verifications.otherDetails.ic != null ? verifications.otherDetails.ic : "" ;
    this.nationality = verifications.otherDetails != null && verifications.otherDetails.nationality != null ? verifications.otherDetails.nationality : "" ;
    this.dateOfIssue = verifications.otherDetails != null && verifications.otherDetails.dateOfIssue != null ? verifications.otherDetails.dateOfIssue : "" ;

    this.rntrc = verifications.otherDetails != null && verifications.otherDetails.rntrc != null ? verifications.otherDetails.rntrc : "" ;

    if (this.displayValue == 'Pending') {
      this.fetchImageMessageShow = true;
    }

    this.mrzCheckDigitResult = (verifications.mrzCheckDigitResult != null) ? this.getMRZCheckDigit(verifications.mrzCheckDigitResult) : "";

    if(this.showOtherInfo){
      this.barcodeOutput = verifications.barcodeOutput != null ?  JSON.parse(verifications.barcodeOutput) : "";
      this.barcodeOutputString = verifications.barcodeOutput != null ?  (verifications.barcodeOutput) : "";
      this.ocrParseOutput = verifications.ocrParseOutput != null ?  JSON.parse(verifications.ocrParseOutput) : "";
      // this.mrzOcrComparisonResult = verifications.mrzOcrComparisonResult != null ?  JSON.parse(verifications.mrzOcrComparisonResult) : ""; // removed As requested.
    }

    if(verifications.liveVideo) {
      if(verifications.agentVerificationDTO != null) {
        this.agentDetails = true;
        this.agentComment = verifications.agentVerificationDTO.comment;
        this.agentSocialResult = verifications.agentVerificationDTO.socialResult != null ?  JSON.parse(verifications.agentVerificationDTO.socialResult) : "";

        this.agentVerifyName = verifications.agentVerificationDTO.verifyName;
        this.agentVerifyDoc = verifications.agentVerificationDTO.verifyDoc;
        this.agentVerifyDob = verifications.agentVerificationDTO.verifyDob;
        this.agentVerifyCountryState = verifications.agentVerificationDTO.verifyCountryState;
        this.verifiedByAgentName = verifications.agentVerificationDTO.verifiedByAgentName;
        this.verifiedTime = verifications.agentVerificationDTO.verifiedTime;
        this.iterateSocialDetails( this.agentSocialResult);
      }
    }

    this.activeHeartAPI = this.userType === 'super_admin' || this.userType === 'tenant_admin' && this.enableIDParsedData;
    // this.otherDetails = (this.activeHeartAPI == true) ? !this.activeHeartAPI : this.otherDetails;

    this.ocrOutputParsedDetails = verifications.ocrOutputParsed != null ? (verifications.ocrOutputParsed) : null;
    this.ocrOutputBackParsedDetails = verifications.ocrOutputParsedBack != null ? (verifications.ocrOutputParsedBack) : null;

    this.prevTransactionHistory = verifications.userVerificationHistoryList != null ? this.transactionHistory(verifications.userVerificationHistoryList) : null;
    this.showEnduserHistorySection = ((this.displayValue === 'Success' || this.displayValue === 'Failed') ? true : false);
  }

  getTimeZone() {
    // var offset = date.getTimezoneOffset(), o = Math.abs(offset);
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  iterateSocialDetails(result) {

    this.socialDetails = result ;
    this.registeredAccounts = [];

    const res = (result);
    Object.entries(res).forEach(([key1, value1], index) => {
      Object.entries(value1).forEach(([key2, value2], index) => {
        if(key2 === 'number') {
          this.number = value2;
        }

        if(key2 === 'risk_score') {
          this.riskScore = value2;
        }

        if(key2 === 'account_details') {
          Object.entries(value2).forEach(([key3, value3], index) => {
            Object.entries(value3).forEach(([key4, value4], index) => {
              if(key4 === 'registered' && value4 === true) {
                let customObj = new SocialAccount();
                customObj.name = key3;
                customObj.image = `assets/social-img/${key3}`+`.png`;
                this.registeredAccounts.push(customObj);
              }
            });
          });
        }
      });
    });
    return res;
  }

  getMRZCheckDigit(mrzCheckDigit){
    const res = JSON.parse(mrzCheckDigit);
    this.mrzChechDigit = res ;

    this.docNum = this.mrzChechDigit.docNumber;
    this.dob = this.mrzChechDigit.dateOfBirth ;
    this.doe = this.mrzChechDigit.dateOfExpiry;
    this.personalNum = this.mrzChechDigit.personalNumber;
    this.overall = this.mrzChechDigit.overall;

    return res;
  }


  getLiveness(liveness) {
    if (liveness && liveness.live) {
      return liveness.live === 'REAL' ? true : false;
    }
    return false;
  }

  // getBarcodeOutput(barcodeOutput) {
  //   console.info('=== Test ==='+barcodeOutput);
  //   var obj = JSON.parse(barcodeOutput);
  //   var res = [];

  //   for (let key in obj) {
  //     console.log ('key: ' +  key + ',  value: ' + obj[key]);
  //  }

  //   res.push(obj);

  //   return null;
  // }

  getCustomProperties(customProperties) {
    if (customProperties && customProperties.CPF) {
      return customProperties.CPF;
    }
    return null;
  }

  getForgeryStatus(forgeryStatus) {
    if (forgeryStatus === 'REAL') {
      return 'Success';
    } else if (forgeryStatus === 'N/A') {
      this.getTenantConfigurationDetail();
      return forgeryStatus;
    } else if (forgeryStatus === null || forgeryStatus === '') {
      this.getTenantConfigurationDetail();
      return false;
    } else if (forgeryStatus === 'bad_quality') {
      return 'Bad Quality';
    } else {
      return 'Failed';
    }
  }

  getLivenessColor() {
    if (typeof this.livenessDTO == 'boolean' && !this.livenessDTO) {
      return { 'background-color': 'red' };
    } else if (typeof this.livenessDTO == 'boolean' && this.livenessDTO) {
      return { 'background-color': 'green' };
    } else {
      return {
        'background-color': 'none',
        color: '#696969',
        'padding-left': '0',
      };
    }
  }

  setLableColor() {
    if (!this.forgeryStatus) {
      return { 'background-color': 'red' };
    } else if (this.forgeryStatus == 'Success') {
      return { 'background-color': 'green' };
    } else if (this.forgeryStatus == 'N/A') {
      return { 'background-color': 'white', color: '#696969', padding: 0 };
    } else {
      return { 'background-color': '#dc3545' };
    }
  }

  transform() {
    if (this.qrCode) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/png;base64, ' + this.qrCode
      );
    } else {
      return false;
    }
  }

  enableMap() {
    if (this.latitude == null || this.longitude == null) {
      return true;
    } else {
      this.mapURL = this.getmapURL(this.latitude, this.longitude);
      return false;
    }
  }

  getmapURL(long, lat) {
    return (
      'https://maps.google.com/maps?q=' +
      long +
      ',' +
      lat +
      '&z=15&output=embed'
    );
  }

  onEditStatus() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) this.enableEditView = true;
    else this.enableEditView = false;
  }

  selectStatus(event) {
    this.userStatus = event.target.value;
  }

  onSwitchVideo() {
    window.open(environment.liveUrl+'/room/', '_blank');
  }

  updateStatus() {
    this.spinner.show();
    const body = {
      status: this.userStatus,
    };
    if (this.userType == 'agent') {
      this.verificationsService
        .updateVerificationStatusByAgent(this.identifier, body)
        .subscribe(
          (result) => {
            this.alertService.success('User status has updated Successfully',this.options);
            this.spinner.hide();
            this.onBack();
          },
          (e) => {
            this.spinner.hide();
            this.alertService.error(e.error.errorMessage, this.options);
          }
        );
    } else {
      this.verificationsService
        .updateVerificationStatus(this.identifier, body)
        .subscribe(
          (result) => {
            this.alertService.success('User status has updated Successfully',this.options);
            this.spinner.hide();
            this.onBack();
          },
          (e) => {
            this.spinner.hide();
            this.alertService.error(e.error.errorMessage, this.options);
          }
        );
    }
  }
  displayStyle = "none";
  videoURL: SafeResourceUrl;

  updateVideoStatus() {
    this.spinner.show();
    if (this.verifyStatus == 'pending') {

      // console.log(this.email);
      this.verificationsService.assignVideoCall(this.queueId,this.email).subscribe(
        (result) => {
          //opening room with token
          // var url = environment.liveUrl+'/room/'  + this.identifier + '/Agent/' + this.token + '/' + environment.baseUrl.replace("https://", "");
          var url = environment.liveUrl+'/room/' + this.identifier + '/Agent/' + this.token + '/' + environment.baseUrl.replace("https://", "");

          // popup
          // this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://idmlive.idmerit.com/room/' + this.identifier + '/Agent/' + this.token + '/' + environment.baseUrl.replace("https://", ""));

          // //openPopup() {
          //  this.displayStyle = "block";
          // //}
          // popup end

            window.open(url, '_blank');

           // window.open(url.toString(), "mypopup", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=10 top=100");

          this.verifyStatus = 'in_progress';
          this.spinner.hide();
        },
        (e) => {
          this.spinner.hide();
          this.alertService.error(e.error.errorMessage, this.options);
        }
      );
    } else if (this.verifyStatus == 'in_progress') {
      this.verificationsService.unAssignVideoCall(this.queueId).subscribe(
        (result) => {
          this.alertService.success(
            'Video status has updated successfully',
            this.options
          );
          this.spinner.hide();
          this.verifyStatus = 'pending';
        },
        (e) => {
          this.spinner.hide();
          this.alertService.error(e.error.errorMessage, this.options);
        }
      );
    }
  }

  closePopup() {
    this.displayStyle = "none";
  }

  // ngAfterViewInit() {
  //   let mediaSource;
  //   if ("MediaSource" in window && MediaSource.isTypeSupported(this.mimeCodec)) {
  //     const mediaSource = new MediaSource();
  //     (this.video.nativeElement as HTMLVideoElement).src = URL.createObjectURL(
  //       mediaSource
  //     );
  //     mediaSource.addEventListener("sourceopen", () =>
  //       this.sourceOpen(mediaSource)
  //     );
  //   } else {
  //     alert("Unsupported MIME type or codec: "+ this.mimeCodec);
  //   }
  // }

  sourceOpen(mediaSource) {
    const sourceBuffer = mediaSource.addSourceBuffer(this.mimeCodec);
    const token = "some token"; // Load the token from some service
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.https
      .get(this.assetURL, { headers, responseType: "blob" })
      .subscribe(blob => {
        sourceBuffer.addEventListener("updateend", () => {
          if (!sourceBuffer.updating && mediaSource.readyState === 'open') {
            // mediaSource.endOfStream();

          this.video.nativeElement.play();
          }

        });
        blob.arrayBuffer().then(x => sourceBuffer.appendBuffer(x));
      });
  }

  transactionHistory(data) {
    let list = [];
    data.forEach((element) => {
      const viewUrl = window.location.origin + `/verifications/${element.uuid}/view?tenantID=${this.tenantId}&pageOffset=0`;
      //const viewUrl = window.location.origin + `/verifications/62325791-9b40-491c-ad33-6b4e5dec1c6e/view?tenantID=${this.tenantId}&pageOffset=0` ;

      let obj = {
        uuid: element.uuid,
        requested_time: moment(element.requested_time).format('MMMM Do YYYY, h:mm:ss a'),
        viewUrl: viewUrl
      };
      list.push(obj);
    });
    return list;
  }

  openHistoryTransaction(data) {
    window.open(data.viewUrl, "_blank");
  }
}
